import { IResponse, ISetUp, cookies, me } from '@dto';
import { Read } from './read';
import { ModuleDefault } from '@modules/default';
import { Create } from './create';
import { Update } from './update';
import { Delete } from './delete';

export class MeModule extends ModuleDefault {
  private _read: Read;
  private _create: Create;
  private _update: Update;
  private _delete: Delete;

  // Read
  public get: () => Promise<IResponse<me.IGet>>;
  public getPreferences: () => cookies.IPref;
  public permissions: () => Promise<IResponse<me.IPermission>>;
  public getDefaultPermission: () => Promise<me.IDefaultPermission>;
  public getUserMFA: () => Promise<IResponse<me.IGetUserMFA>>;

  // Create
  public setPreferences: (pref: cookies.IPref) => boolean;
  public createUserMFA: (body: me.IBodyCreateUserMFA) => Promise<IResponse<me.ICreateUserMFA>>;
  public getActiveUserMFA: (body: me.IBodyGetActiveUserMFA) => Promise<IResponse<me.IGetActiveUserMFA>>;
  public sendUserMFA: (body: any) => Promise<any>;

  // Update
  public update: (body: me.IProfile) => Promise<IResponse<me.IProfileResponse>>;
  public setDefault: (params: me.IParamsSetDefault, body: me.IBodySetDefault) => Promise<void>;

  // Delete
  public deleteUserMFA: (body: me.IBodyDeleteUserMFA) => Promise<any>;

  constructor (setup: ISetUp) {
    super(setup);
    this._read = new Read(setup);
    this._create = new Create(setup);
    this._update = new Update(setup);
    this._delete = new Delete(setup);

    this.get = this._read.get;
    this.getPreferences = this._read.getPreferences;
    this.permissions = this._read.permissions;
    this.getDefaultPermission = this._read.getDefaultPermission;
    this.getUserMFA = this._read.getUserMFA;

    this.setPreferences = this._create.setPreferences;
    this.createUserMFA = this._create.createUserMFA;
    this.getActiveUserMFA = this._create.getActiveUserMFA;
    this.sendUserMFA = this._create.sendUserMFA;

    this.deleteUserMFA = this._delete.deleteUserMFA;

    this.update = this._update.update;
    this.setDefault = this._update.setDefault;
  }
}
