import { Read } from './read';
import { Update } from './update';
import { Create } from './create';
import { IList, IResponse, ISetUp, invite } from '@dto';
import { ModuleDefault } from '../default';

export class InviteModule extends ModuleDefault {
  private _read: Read;
  private _update: Update;
  private _create: Create;

  // Read
  public getMine: (params?: { page: number; size: number; }) => Promise<IResponse<IList<invite.IGetMine>>>;

  // Create
  public acceptAll: (body: any) => Promise<any>;

  // Update
  public refuse: (inviteId: string) => Promise<void>;
  public accept: (inviteId: string) => Promise<void>;

  constructor (setup: ISetUp) {
    super(setup);
    this._read = new Read(setup);
    this._update = new Update(setup);
    this._create = new Create(setup);

    this.getMine = this._read.getMine;
    this.acceptAll = this._create.acceptAll;
    this.refuse = this._update.refuse;
    this.accept = this._update.accept;
  }
}
