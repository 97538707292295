import { IResponse, me } from '@dto';
import { ModuleDefault } from '../default';

export class Update extends ModuleDefault {
  public update (body: me.IProfile): Promise<IResponse<me.IProfileResponse>> {
    return this.request.patch(`${this.setup.urlBase}/me`, body);
  }

  public setDefault (params: me.IParamsSetDefault, body: me.IBodySetDefault): Promise<void> {
    const queryParams = this.utils.paramSerializerURL(params);
    return this.request.put(`${this.setup.urlBase}/me${queryParams}/set-default`, body);
  }
}
