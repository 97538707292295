import { ISetUp, TApplication } from '@dto';

class SessionService {
    private setup: ISetUp;

    constructor (setup: ISetUp) { this.setup = setup; }

    get hasWindow (): boolean {
        return typeof window !== 'undefined';
    }

    public has (key: TApplication): boolean {
        return key in window.sessionStorage;
    }

    public get (key: TApplication): string {
        return this.hasWindow ? sessionStorage.getItem(`${this.setup.acronym}mp_${key}`) : '';
    }

    public getJson<T> (key: TApplication): T {
        const data = this.get(key);
        return data ? JSON.parse(atob(data)) : {};
    }

    public set (key: TApplication, value: string): void {
        // tslint:disable-next-line
        !this.hasWindow || sessionStorage.setItem(`${this.setup.acronym}mp_${key}`, value);
    }

    public setJson (key: TApplication, value: any): void {
        // tslint:disable-next-line
        this.set(key, btoa(JSON.stringify(value)));
    }

    public remove (key: TApplication): void {
        // tslint:disable-next-line
        !this.hasWindow || sessionStorage.removeItem(`${this.setup.acronym}mp_${key}`);
    }

    public clear (): void {
        // tslint:disable-next-line
        !this.hasWindow || sessionStorage.clear();
    }
}

export default SessionService;
