import { Read } from './read';
import { ISetUp } from '@dto';
import { ModuleDefault } from '../default';

export class SupportModule extends ModuleDefault {
  private _read: Read;

  // Read
  public getInitials: (firstName: string, lastName: string) => string;

  constructor (setup: ISetUp) {
    super(setup);
    this._read = new Read(setup);

    this.getInitials = this._read.getInitials;
  }
}
