import { Read } from './read';
import { Create } from './create';
import { ModuleDefault } from '../default';
import { IResponse, ISetUp, merchant } from '@dto';

export class MerchantModule extends ModuleDefault {
  private _read: Read;
  private _create: Create;

  // Read
  public getAll: (data: any) => any;

  // Create
  public create: (data: merchant.ICreate) => Promise<IResponse<merchant.ICreate>>;

  constructor (setup: ISetUp) {
    super(setup);
    this._read = new Read(setup);
    this._create = new Create(setup);

    this.getAll = this._read.getAll;
    this.create = this._create.create;
  }
}
