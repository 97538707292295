import { TApplication, cookies } from '@dto';
import { ModuleDefault } from '../default';

export class Delete extends ModuleDefault {
  public deleteCookies (app: TApplication, logout = false) {
    const path = '/';
    const dataUrl: { app: TApplication; path?: string; search?: string } = { app };

    if (!logout) {
      dataUrl.path = window.location.pathname;
      dataUrl.search = window.location.search;
    }

    this.cookie.set(cookies.EKey.redirect, dataUrl, path);

    this.local.clear();
    this.session.clear();

    this.cookie.remove(cookies.EKey.token, path);
    this.cookie.remove(cookies.EKey.refresh_token, path);

    return true;
  }
}
