import CookieService from './cookies'
import LocalService from './local'
import SessionService from './session'
import { ISetUp, TTheme, EAcronym, EUrl, IData, EDomain, TEnv } from '@dto'

export class Storage {
  private _env: TEnv
  private _theme: TTheme

  public cookie: CookieService
  public localStorage: LocalService
  public sessionStorage: SessionService

  constructor(data: IData) {
    this._env = data.environment
    this._theme = data.theme

    this.cookie = new CookieService(this.setup)
    this.localStorage = new LocalService(this.setup)
    this.sessionStorage = new SessionService(this.setup)
  }

  private get setup(): ISetUp {
    return {
      theme: this._theme,
      domain: this.domain,
      environment: this._env,
      urlBase: this.urlBase,
      acronym: this.acronym
    }
  }

  public get urlBase(): EUrl {
    return EUrl[this._env]
  }

  public get domain(): EDomain {
    return EDomain[this._theme]
  }

  public get acronym(): EAcronym {
    return EAcronym[this._env]
  }
}
