import { ISetUp, TApplication, authorization, IResponse } from '@dto';
import { ModuleDefault } from '../default';
import { Read } from './read';
import { Delete } from './delete';

export class AuthorizationModule extends ModuleDefault {
  private _read: Read;
  private _delete: Delete;

  // Read
  public get: (params: authorization.ISession, application: TApplication, urlProfile: string) => Promise<authorization.IGet>;

  // Delete
  public deleteCookies: (app: TApplication, logout?: boolean) => boolean;

  constructor (setup: ISetUp) {
    super(setup);
    this._read = new Read(setup);
    this._delete = new Delete(setup);

    this.get = this._read.get;
    this.deleteCookies = this._delete.deleteCookies;
  }
}
