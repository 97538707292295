import { MeModule } from '@modules/me';
import { MFAModule } from '@modules/mfa';
import { OnboardingModule } from '@modules/onboarding';
import { AccountModule } from '@modules/account';
import { AuthorizationModule } from '@modules/authorization';
import { InviteModule } from '@modules/invite';
import { SupportModule } from '@modules/support';
import { MerchantModule } from '@modules/merchant';
import { EAcronym, EDomain, EUrl, ICallbackError, IData, ISetUp, TEnv, TTheme } from '@dto';

export class ProfileCore {
  private _env: TEnv;
  private _theme: TTheme;
  public callbacksError: ICallbackError;

  public me: MeModule;
  public mfa: MFAModule;
  public onboarding: OnboardingModule;
  public account: AccountModule;
  public authorization: AuthorizationModule;
  public invite: InviteModule;
  public support: SupportModule;
  public merchant: MerchantModule;

  constructor(data: IData = { environment: 'production', theme: 'pagarme', callbacksError: null }) {
    this._env = data.environment;
    this._theme = data.theme;

    this.callbacksError = data.callbacksError;
    this.me = new MeModule(this.setup);
    this.mfa = new MFAModule(this.setup);
    this.onboarding = new OnboardingModule(this.setup);
    this.account = new AccountModule(this.setup);
    this.authorization = new AuthorizationModule(this.setup);
    this.invite = new InviteModule(this.setup);
    this.support = new SupportModule(this.setup);
    this.merchant = new MerchantModule(this.setup);
  }

  private get setup(): ISetUp {
    return {
      callbacksError: this.callbacksError,
      theme: this._theme,
      domain: this.domain,
      environment: this._env,
      urlBase: this.urlBase,
      acronym: this.acronym
    };
  }

  public get urlBase(): EUrl {
    return EUrl[this._env];
  }

  public get domain(): EDomain {
    const [hostname] = window.location.hostname.match(/(\.pagar\.me|\.mundipagg\.com)/g) || ['.pagar.me']
    return hostname as EDomain;
  }

  public get acronym(): EAcronym {
    return EAcronym[this._env];
  }
}
