import { ModuleDefault } from '../default';
import { account, IList, IResponse } from '@dto';

export class Read extends ModuleDefault {
  public getAll (merchantId: string, params: account.IParamsGetAll = { page: 1, size: 30, type: 'production' }) {
    if (params.identifier) {
      const validDocument = /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/;

      if (validDocument.test(params.identifier)) {
        const filteredTerm = params.identifier.replace(/[`.\- <>{}[\]\\/]/gi, '');
        params.identifier = filteredTerm;
      }
    }

    const queryParams = this.utils.paramSerializer(params);
    const url = `${this.setup.urlBase}/merchants/${merchantId}/accounts${queryParams}`;
    return this.request.get<IResponse<IList<account.IGetAll>>>(url);
  }
}
