import { ModuleDefault } from '../default';

export class Update extends ModuleDefault {
  public accept (inviteId: string): Promise<void> {
    return this.request.patch(`${this.setup.urlBase}/invites/${inviteId}/accept`, {});
  }

  public refuse (inviteId: string): Promise<void> {
    return this.request.patch(`${this.setup.urlBase}/invites/${inviteId}/refuse`, {});
  }
}
