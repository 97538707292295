import http from '../http'
import { requestInterceptor, errorRequestInterceptor, errorResponseInterceptor, responseInterceptor } from '../interceptors'

http.interceptors.request.use(requestInterceptor, errorRequestInterceptor)
http.interceptors.response.use(responseInterceptor, errorResponseInterceptor)

export interface IRequest {
  get: <T>(endpoint: string) => Promise<T>
  post: <T>(endpoint: string, body: any) => Promise<T>
  delete: <T>(endpoint: string, body: any) => Promise<T>
  patch: <T>(endpoint: string, body: any) => Promise<T>
  put: <T>(endpoint: string, body: any) => Promise<T>
}

export const request: IRequest = {
  get: <T>(endpoint: string): Promise<T> => http.get<T>(endpoint).catch((e) => Promise.reject(e)) as any,
  post: <T>(endpoint: string, body: any): Promise<T> => http.post(endpoint, body).catch((e) => Promise.reject(e)) as any,
  delete: <T>(endpoint: string, body: any): Promise<T> => http.delete(endpoint, { data: body }).catch((e) => Promise.reject(e)) as any,
  patch: <T>(endpoint: string, body: any): Promise<T> => http.patch(endpoint, body).catch((e) => Promise.reject(e)) as any,
  put: <T>(endpoint: string, body: any): Promise<T> => http.put(endpoint, body).catch((e) => Promise.reject(e)) as any
}
