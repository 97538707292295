import Cookies from 'js-cookie'
import { request } from '../../http/requests'
import UtilsService from '@modules/utils'
import Settings from '../../http/Settings'
import { clientSettings } from '../../commons/read'

export function refreshToken() {
  const { domain, envAcronym, urlAuth } = Settings.getInstance()
  const rt = JSON.parse(Cookies.get(`${envAcronym}mp_rt`))
  const body = clientSettings(rt)
  const utils = new UtilsService()
  const params = utils.paramSerializer(body).replace('?', '')
  return request
    .post<any>(`${urlAuth}/connect/token`, params)
    .then((data) => {
      const res = data
      const path = '/'
      const today = new Date()
      const expires = new Date(today.getTime() + (res.expires_in * 1000))
      const expiresTk = new Date(today.getTime() + (res.expires_in * 850))
      const refreshTk = Object.assign({ ...rt, refresh_token: res.refresh_token })
      Cookies.set(`${envAcronym}mp_tk`, res.access_token, { expires, path, domain })
      Cookies.set(`${envAcronym}mp_rt`, JSON.stringify(refreshTk), { expires, path, domain })
      Cookies.set(`${envAcronym}mp_et`, expiresTk, { expires: expiresTk, path, domain })

      return res
    })
    .catch((err) => {
      throw err
    })
}
