export * as cookies from './cookie.dto';
export * as me from './me.dto';
export * as onboarding from './onboarding.dto';
export * as account from './account.dto';
export * as authorization from './authorization.dto';
export * as invite from './invite.dto';
export * as merchant from './merchant.dto';
export * as mfa from './mfa.dto'

export type TEnv = 'development' | 'staging' | 'production';
export type TTheme = 'pagarme' | 'jpmorgan';
export type TLanguage = 'pt-BR' | 'en-US';
export type TThemeMode = 'light' | 'dark' | 'default';
export type TGender = 'Other' | 'Male' | 'Female';
export type TRole = 'root' | 'manager' | 'developer' | 'analyst' | 'support' | 'observer' | 'controller' | 'owner' | 'admin';
export type TApplication = 'profile' | 'dash' | 'company' | 'shop' | 'finance' | 'hubdash' | 'onboarding' | 'backoffice';
export type TAccount = 'production' | 'test';
export type TBusinessModel = 'p_s_p' | 'gateway' | 'merchant_business_model' | 'gateway_and_psp';
export type TStatus = 'active' | 'inactive' | 'pending';

export interface IResponse<T> {
  code: number;
  notifications?: any;
  is_valid: boolean;
  data: T;
}

export interface IList<T> {
  items: Array<T>;
  pagination: {
    current_page: number;
    page_size: number;
    total_items: number;
    total_page: number;
  };
}

export interface ICallbackError {
  unauthorized?: () => void;
}

export interface IData { environment: TEnv; theme: TTheme; callbacksError?: ICallbackError; }

export interface ISetUp {
  theme: TTheme;
  domain: EDomain;
  environment: TEnv;
  urlBase: EUrl;
  acronym: EAcronym;
  callbacksError?: ICallbackError;
}

export enum EUrl {
  production = 'https://api.mundipagg.com/profile/v2',
  staging = 'https://stgapi.mundipagg.com/profile/v2',
  development = 'https://devapi.mundipagg.com/profile/v2'
}

export type EDomain = '.mundipagg.com' | '.pagar.me'

export enum EAcronym {
  production = '',
  staging = 'stg_',
  development = 'dev_'
}
