import { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import Settings from '../http/Settings'
import { refreshToken } from '../auth/connect/create'

export function verifyAuth(config, token) {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

export function handleBaseResponse(error) {
  return {
    type: 'response',
    status: error && error.response ? error.response.status : null,
    message: error.message,
    stack: error.stack,
    body: error && error.response ? error.response.data : null
  }
}

export function handleBodyRequest(error) {
  return {
    type: 'request',
    status: error.request.status,
    message: error.message,
    stack: error.stack
  }
}

export function clientSettings(rt) {
  if (rt.type === 'refresh_token') {
    return {
      grant_type: 'refresh_token',
      refresh_token: rt.refresh_token,
      client_id: 'Mundipagg_Id_WebApp',
      client_secret: 'csct_eaLpqVghWUEQrmd2'
    }
  }
  return {
    grant_type: 'refresh_token',
    refresh_token: rt.refresh_token,
    client_id: 'Backoffice_Id_WebApp',
    client_secret: 'csct_X7834RLi2nun2N6Q'
  }
}

export function handleAutoRefresh(): Promise<AxiosRequestConfig | void> {
  const { envAcronym } = Settings.getInstance()
  const autoRefresh = Cookies.get(`${envAcronym}mp_et`) === undefined
  const hasToken = Cookies.get(`${envAcronym}mp_tk`) !== undefined
  const hasRefreshToken = Cookies.get(`${envAcronym}mp_rt`) !== undefined
  if (!hasToken || !hasRefreshToken) {
    const status = 401
    return Promise.reject({
      status,
      response: { status }
    })
  }

  const isRefreshing = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem(`${envAcronym}mp_refreshing`)) : false
  if (autoRefresh && hasToken && !isRefreshing) {
    localStorage.setItem(`${envAcronym}mp_refreshing`, 'true')
    return refreshToken().finally(() => {
      localStorage.setItem(`${envAcronym}mp_refreshing`, 'false')
    })
  }
  return Promise.resolve()
}
