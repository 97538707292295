class UtilsService {
    public paramSerializer (params: any = {}) {
        const queryParams = Object.entries(params) as string[][];
        let query: string;
        if (queryParams.length > 0) {
            query = `?${new URLSearchParams(queryParams)}`;
        }

        return query || '';
    }

    public paramSerializerURL (params = {}): string {
        let queryParams: any = Object.entries(params);
        if (queryParams.length > 0) {
            queryParams = queryParams.reduce((prev, actual, index) => {
                if (actual[0] === 'merchant_id' || actual[0] === 'merchantId') { actual[0] = 'merchants'; }
                if (actual[0] === 'account_id' || actual[0] === 'accountId') { actual[0] = 'accounts'; }
                return index === 0 ? prev + `${actual[0]}/${actual[1]}` : prev + `/${actual[0]}/${actual[1]}`;
            }, '/');
        }
        return queryParams;
    }

    public clean (obj: object): object {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj;
    }
}

export default UtilsService;
