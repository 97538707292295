import Cookies from 'js-cookie'
import { verifyAuth, handleBodyRequest } from '../../commons/read'
import Settings from '../Settings'

export function requestInterceptor(config) {
  const { envAcronym, theme } = Settings.getInstance()
  const token = Cookies.get(`${envAcronym}mp_tk`)
  const request = verifyAuth(config, token)
  const pref = Cookies.get(`${envAcronym}mp_pref`) ? JSON.parse(atob(Cookies.get(`${envAcronym}mp_pref`))) : { timezone: 'E. South America Standard Time', language: 'pt-BR', appearance: { theme: 'mundipagg', mode: 'light' } }

  request.headers['Accept-Language'] = pref.language
  request.headers['X-Theme'] = theme
  request.headers.Timezone = pref.timezone
  return request
}

export function errorRequestInterceptor(error) {
  const body = handleBodyRequest(error)
  return Promise.reject(body)
}

export function requestInterceptorNoHeader(config) {
  return config
}
