import { IResponse, cookies, me } from '@dto';
import { ModuleDefault } from '@modules/default';

export class Create extends ModuleDefault {
  public setPreferences (pref: cookies.IPref): boolean {
    this.cookie.set(cookies.EKey.preference, pref);
    return true;
  }

  public createUserMFA (body: me.IBodyCreateUserMFA): Promise<IResponse<me.ICreateUserMFA>> {
    return this.request.post<IResponse<me.ICreateUserMFA>>(`${this.setup.urlBase}/me/security/mfa`, body);
  }

  public getActiveUserMFA (body: me.IBodyGetActiveUserMFA): Promise<IResponse<me.IGetActiveUserMFA>> {
    return this.request.post<IResponse<me.IGetActiveUserMFA>>(`${this.setup.urlBase}/me/security/mfa/active`, body);
  }

  public sendUserMFA (body = {}) {
    return this.request.post(`${this.setup.urlBase}/me/security/mfa/send`, body);
  }
}
