import { IResponse, cookies, me } from '@dto';
import { ModuleDefault } from '../default';

export class Read extends ModuleDefault {
  public get (): Promise<IResponse<me.IGet>> {
    return this.request.get<IResponse<me.IGet>>(`${this.setup.urlBase}/me`);
  }

  public getPreferences (): cookies.IPref {
    let pref: cookies.IPref;

    const preferencesObj: cookies.IPref = {
      timezone: 'E. South America Standard Time',
      language: 'pt-BR',
      appearance: {
        theme:
        'pagarme',
        mode: 'light'
      }
    };
    const preferencesCookie = this.cookie.get<cookies.IPref>(cookies.EKey.preference);

    if (preferencesCookie) {
      if (!preferencesCookie.appearance) {
        preferencesCookie.appearance = preferencesObj.appearance;
        pref = Object.assign({}, preferencesCookie);
        this.cookie.set(cookies.EKey.preference, pref);
      } else {
        pref = Object.assign({}, preferencesCookie);
      }
    } else {
      pref = Object.assign({}, preferencesObj);
      this.cookie.set(cookies.EKey.preference, pref);
    }

    return pref;
  }

  public permissions (): Promise<IResponse<me.IPermission>> {
    return this.request.get<IResponse<me.IPermission>>(`${this.setup.urlBase}/me/permissions`);
  }

  public getDefaultPermission (): Promise<me.IDefaultPermission> {
    return this.request.get<me.IPermission>(`${this.setup.urlBase}/me/permissions`)
      .then((r) => {
        const allPermissions = [...r.merchants, ...r.companies]
        const permissionDefault: me.IDefaultPermission = allPermissions.find(permission => permission.is_default)
        return Promise.resolve(permissionDefault)
      });
  }

  public getUserMFA (): Promise<IResponse<me.IGetUserMFA>> {
    return this.request.get<IResponse<me.IGetUserMFA>>(`${this.setup.urlBase}/me/security/mfa`);
  }
}
