import { IResponse, TApplication, authorization } from '@dto';
import { ModuleDefault } from '../default';


export class Read extends ModuleDefault {
  public async get (params: authorization.ISession, application: TApplication, urlProfile: string): Promise<authorization.IGet> {
    let newParams: authorization.IParams = {};
    let session: authorization.ISession = {};
    const {ignoreCache, ...patternParams} = params

    if (application) {
      session = this.session.getJson(application);
    }

    const hasParams = Object.keys(patternParams).length;
    const hasAccInParams = patternParams?.account_id
    const hasSession = Object.keys(session).length;
    const isSameMerchantInParamsAndSession = patternParams?.merchant_id === session?.merchant_id;
    const isSessionBased = hasSession && isSameMerchantInParamsAndSession && !hasAccInParams

    if (isSessionBased) {
      const { merchant_id, account_id } = session;
      newParams = { merchants: merchant_id, accounts: account_id };
    } else if (hasParams) {
      const { merchant_id, account_id } = patternParams;
      newParams = { merchants: merchant_id, accounts: account_id };
    }

    this.session.clear();
    newParams = this.utils.clean(newParams);

    let queryParams = this.utils.paramSerializerURL(newParams);

    if (ignoreCache) {
      const ignoreCacheParam = this.utils.paramSerializer({ ignoreCache })
      queryParams+= ignoreCacheParam
    }

    const url = `${this.setup.urlBase}/authorization${queryParams}`;
    return this.request.get<authorization.IGet>(url)
      .then((response) => {
        const isRoot = response.user.is_root;
        const isEmployee = response.user.is_employee;
        const isProfile = window.location.href.includes('profile');
        const isID = window.location.href.includes('id');
        const isLocalProfile = window.location.href.includes('910');
        const isLocalID = window.location.href.includes('900');

        if (response.merchant) {
          const toSession = {
            merchant_id: response.merchant.id,
            account_id: response.merchant.account.id
          };

          if (application) { this.session.setJson(application, toSession); }
        } else if (!isRoot && !isEmployee && !isProfile && !isID && !isLocalID &&!isLocalProfile) {
          window.location.assign(urlProfile);
        }

        return Promise.resolve(response);
      })
      .catch(err => Promise.reject(err));
  }
}
