import { ModuleDefault } from '../default';
import { mfa, IResponse } from '@dto';

export class Create extends ModuleDefault {
  public resetMFaUser (email: string, body: mfa.IBodyResetMfa) {
    return this.request.post(`${this.setup.urlBase}/mfa/${email}/reset`, body)
  }

  public createUserMFA (email: string, body: mfa.IBodyCreateUserMFA): Promise<IResponse<mfa.ICreateUserMFA>> {
    return this.request.post<IResponse<mfa.ICreateUserMFA>>(`${this.setup.urlBase}/mfa/${email}`, body);
  }

  public getActiveUserMFA (email: string, body: mfa.IBodyGetActiveUserMFA): Promise<IResponse<mfa.IGetActiveUserMFA>> {
    return this.request.post<IResponse<mfa.IGetActiveUserMFA>>(`${this.setup.urlBase}/mfa/${email}/active`, body);
  }

  public sendUserMFA (email: string, useFallback, body) {
    const optWhatssapp = useFallback ? '?use_fallback=true' : ''

    return this.request.post(`${this.setup.urlBase}/mfa/${email}/send${optWhatssapp}`, body);
  }
}
