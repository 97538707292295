import { ModuleDefault } from '../default';

export class Read extends ModuleDefault {
  public getInitials (firstName: string, lastName: string): string {
    const nameSplit = lastName.split(' ');
    const lastNameSplit = nameSplit[nameSplit.length - 1];

    return `${firstName.charAt(0).toUpperCase()}${lastNameSplit.charAt(0).toUpperCase()}`;
  }
}
