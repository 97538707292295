import { Read } from './read';
import { ModuleDefault } from '../default';
import { ISetUp, account, IResponse, IList } from '@dto';

export class AccountModule extends ModuleDefault {
  private _read: Read;

  // Read
  public getAll: (merchantId: string, params?: account.IParamsGetAll) => Promise<IResponse<IList<account.IGetAll>>>;

  constructor (setup: ISetUp) {
    super(setup);
    this._read = new Read(setup);

    this.getAll = this._read.getAll;
  }
}
