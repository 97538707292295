import { Create } from './create';
import { Read } from './read';
import { ModuleDefault } from '../default';
import { IResponse, ISetUp, mfa } from '@dto';

export class MFAModule extends ModuleDefault {
  private _create: Create;
  private _read: Read;

  // Read
  public getUserMFA: (email: string) => Promise<IResponse<mfa.IGetUserMFA>>;

  // Create
  public createUserMFA: (email: string, body: mfa.IBodyCreateUserMFA ) => Promise<IResponse<mfa.ICreateUserMFA>>;
  public getActiveUserMFA: (email: string, body: object) => Promise<IResponse<mfa.IGetActiveUserMFA>>;
  public sendUserMFA: (email: string, useFallback: boolean, body: object) => Promise<any>;
  public resetMFaUser: (email: string, body: mfa.IBodyResetMfa) => Promise<any>;

  constructor (setup: ISetUp) {
    super(setup);
    this._create = new Create(setup);
    this._read = new Read(setup);

    this.resetMFaUser = this._create.resetMFaUser;
    this.createUserMFA = this._create.createUserMFA;
    this.getActiveUserMFA = this._create.getActiveUserMFA;
    this.sendUserMFA = this._create.sendUserMFA;

    this.getUserMFA = this._read.getUserMFA;
  }
}
