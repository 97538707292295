import { AxiosResponse } from 'axios'
import { handleBaseResponse, handleAutoRefresh } from '../../commons/read'

export function responseInterceptor(response: AxiosResponse) {
  const firstRequestResponseCallback = () => response.data || response
  return handleAutoRefresh().then(firstRequestResponseCallback).catch(firstRequestResponseCallback)
}

export function errorResponseInterceptor(error) {
  const baseResponse = handleBaseResponse(error)
  return handleAutoRefresh()
    .then(() => Promise.reject(baseResponse))
    .catch(() => Promise.reject(baseResponse))
}

export function responseInterceptorToken(response) {
  return response.data || response
}

export function errorResponseInterceptorToken(error) {
  const baseResponse = handleBaseResponse(error)
  return Promise.reject(baseResponse)
}
