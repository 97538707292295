import { ModuleDefault } from '@modules/default';
import { IResponse, ISetUp, TApplication } from '@dto';
import { Read } from './read';
import { Update } from './update';

export class OnboardingModule extends ModuleDefault {
  private _read: Read;
  private _update: Update;

  // Read
  get: <T>(application: TApplication) => Promise<IResponse<T>>;

  // Update
  update: <T>(application: TApplication, body: T) => Promise<void>;

  constructor (setup: ISetUp) {
    super(setup);
    this._read = new Read(setup);
    this._update = new Update(setup);

    this.get = this._read.get;
    this.update = this._update.update;
  }
}
