let instance = {} as Settings

export default class Settings {
  constructor(public theme: string, public domain: string, private env: string) {
    this.theme = theme
    this.domain = domain
    this.env = env
    instance = this
  }

  static getInstance() {
    if (instance) { return instance }
  }

  static setup(theme: string, domain: string, env: string) {
    return new Settings(theme, domain, env)
  }

  get urlAuth() {
    let url
    switch (this.env) {
      case 'local':
        url = 'https://devapi.mundipagg.com/auth/v2'
        break
      case 'staging':
        url = 'https://stgapi.mundipagg.com/auth/v2'
        break
      case 'production':
        url = 'https://api.mundipagg.com/auth/v2'
        break
      default:
        url = 'https://api.mundipagg.com/auth/v2'
    }
    return url
  }

  get envAcronym() {
    let acronym
    switch (this.env) {
      case 'local':
        acronym = 'local_'
        break
      case 'staging':
        acronym = 'stg_'
        break
      case 'production':
        acronym = ''
        break
      default:
        acronym = ''
    }
    return acronym
  }
}
