import axios from 'axios'
import { requestInterceptorNoHeader, errorRequestInterceptor, errorResponseInterceptorToken, responseInterceptorToken } from '../interceptors'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(requestInterceptorNoHeader, errorRequestInterceptor)
axiosInstance.interceptors.response.use(responseInterceptorToken, errorResponseInterceptorToken)

export const requestNoHeader = {
  get: <T>(endpoint: string): Promise<T> => axiosInstance.get<T>(endpoint).catch(e => Promise.reject(e)) as any,
  post: <T>(endpoint: string, body: any): Promise<T> => axiosInstance.post<T>(endpoint, body).catch(e => Promise.reject(e)) as any,
  delete: <T>(endpoint: string, body: any): Promise<T> => axiosInstance.delete<T>(endpoint, { data: body }).catch(e => Promise.reject(e)) as any,
  put: <T>(endpoint: string, body: any): Promise<T> => axiosInstance.put<T>(endpoint, body).catch(e => Promise.reject(e)) as any,
  patch: <T>(endpoint: string, body: any): Promise<T> => axiosInstance.patch<T>(endpoint, body).catch(e => Promise.reject(e)) as any
}
