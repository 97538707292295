import Settings from '@modules/http/Settings'
import Cookies from 'js-cookie'

export function deleteTokens() {
  const path = '/'
  const { domain, envAcronym } = Settings.getInstance()

  window.localStorage.clear()
  window.sessionStorage.clear()

  Cookies.remove(`${envAcronym}mp_tk`, { path, domain })
  Cookies.remove(`${envAcronym}mp_rt`, { path, domain })
}
